import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ConfigContext } from 'context';
import { useContext } from 'react';
import { getPartnerName } from 'partner_configuration';
import { IsMobile } from 'hooks/isMobile';

const MainContainer = styled('main')`
  padding: 40px;
  text-align: justify;
`;

const Title = styled(Typography)`
  margin-bottom: 32px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Paragraph = styled(Typography)`
  margin-bottom: 20px;
`;

const LastUpdatedInfoContainer = styled(Typography)`
  margin-bottom: 24px;
  font-weight: 600;
`;

const MainHeading = styled(Typography)`
  margin-bottom: 24px;
  font-weight: 600;
`;

function TermsOfService() {
  const config = useContext(ConfigContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  const partnerName = getPartnerName(url);
  return (
    <MainContainer style={{ padding: IsMobile() ? '40px' : '80px' }}>
      <Title variant="h1">TERMS OF SERVICE</Title>
      <LastUpdatedInfoContainer variant="body2">
        Last Updated: {config.updateDeteOfTermsOfService}
      </LastUpdatedInfoContainer>
      <Paragraph variant="body2">
        These Terms of Service (&quot;Terms&quot;) apply to your access to and
        use of the websites, mobile applications and other products and services
        (collectively, the &quot;Services&quot;) provided by{' '}
        {config.nameOfCompany}, {config.typeOfCompany}, d/b/a{' '}
        {config.nameOfCompany} (&quot;{config.nameOfCompany},&quot;
        &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). By clicking &quot;I
        Accept&quot; or by using our Services, you agree to these Terms,
        including the mandatory arbitration provision and class action waiver in
        Section 15.{' '}
        <b>If you do not agree to these Terms, do not use our Services.</b>
      </Paragraph>
      <Paragraph variant="body2">
        We may supply different or additional terms in relation to some of our
        Services, and those different or additional terms become part of your
        agreement with us if you use those Services. If there is a conflict
        between these Terms and the additional terms, the additional terms will
        control for that conflict.
      </Paragraph>
      <Paragraph variant="body2">
        We may make changes to these Terms from time to time. If we make
        changes, we will provide you with notice of such changes, such as by
        sending an email, providing a notice through our Services or updating
        the date at the top of these Terms. Unless we say otherwise in our
        notice, the amended Terms will be effective immediately, and your
        continued use of our Services after we provide such notice will confirm
        your acceptance of the changes. If you do not agree to the amended
        Terms, you must stop using our Services.
      </Paragraph>
      <MainHeading variant="h4">1. Privacy</MainHeading>
      <Paragraph variant="body2">
        For information about how we collect, use, share and otherwise process
        information about you, please see our Privacy Policy.
      </Paragraph>
      <MainHeading variant="h4">2. Eligibility</MainHeading>
      <Paragraph variant="body2">
        You must be at least 13 years of age to use our Services. If you are
        under 18 years of age (or the age of legal majority where you live), you
        may use our Services only under the supervision of a parent or legal
        guardian who agrees to be bound by these Terms. If you are a parent or
        legal guardian of a user under the age of 18 (or the age of legal
        majority), you agree to be fully responsible for the acts or omissions
        of such user in relation to our Services. If you use our Services on
        behalf of another person or entity, (a) all references to “you”
        throughout these Terms will include that person or entity, (b) you
        represent that you are authorized to accept these Terms on that
        person&apos;s or entity&apos;s behalf, and (c) in the event you or the
        person or entity violates these Terms, the person or entity agrees to be
        responsible to us.
      </Paragraph>
      <MainHeading variant="h4">
        3. User Accounts and Account Security
      </MainHeading>
      <Paragraph variant="body2">
        You may need to register for an account to access some or all of our
        Services. If you register for an account, you must provide accurate
        account information and promptly update this information if it changes.
        You also must maintain the security of your account and promptly notify
        us if you discover or suspect that someone has accessed your account
        without your permission. If you permit others to use your account
        credentials, you are responsible for the activities of such users that
        occur in connection with your account. We reserve the right to reclaim
        usernames, including on behalf of businesses or individuals that hold
        legal claim, which may include trademark rights, to those usernames.
      </Paragraph>
      <MainHeading variant="h4">
        4. {config.nameOfCompany} Services and Intended Use
      </MainHeading>
      <Paragraph variant="body2">
        The Services include a monitoring device that records audiovisual
        information about the entirety of the camera&apos;s field of view that
        is captured by the camera and relays this information to{' '}
        {config.nameOfCompany}&apos;s advanced computer vision technology in
        order to provide you with alerts, insights, and real-time audio and
        video monitoring of your camera&apos;s field of view via a mobile
        application that integrates with the {config.nameOfCompany} device.
      </Paragraph>

      <MainHeading variant="h4">5. Prohibited Conduct</MainHeading>
      <Paragraph variant="body2">
        You will not violate any applicable law, contract, intellectual property
        right or other third-party right or commit a tort, and you are solely
        responsible for your conduct while using our Services. You will not:
      </Paragraph>

      <Paragraph variant="body2">
        Engage in any harassing, threatening, intimidating, predatory or
        stalking conduct; Use or attempt to use another user’s account without
        authorization from that user and {config.nameOfCompany}; Impersonate or
        post on behalf of any person or entity or otherwise misrepresent your
        affiliation with a person or entity; Sell, resell or commercially use
        our Services; Copy, reproduce, distribute, publicly perform or publicly
        display all or portions of our Services, except as expressly permitted
        by us or our licensors; Modify our Services, remove any proprietary
        rights, notices or markings, or otherwise make any derivative works
        based upon our Services; Use our Services other than for their intended
        purpose and in any manner that could interfere with, disrupt, negatively
        affect or inhibit other users from fully enjoying our Services, or that
        could damage, disable, overburden or impair the functioning of our
        Services in any manner; Reverse engineer any aspect of our Services or
        do anything that might discover source code or bypass or circumvent
        measures employed to prevent or limit access to any part of our
        Services; Use any data mining, robots or similar data gathering or
        extraction methods designed to scrape or extract data from our Services;
        Develop or use any applications that interact with our Services without
        our prior written consent; Send, distribute or post spam, unsolicited or
        bulk commercial electronic communications, chain letters, or pyramid
        schemes; Bypass or ignore instructions contained in our robots.txt file;
        or Use our Services for any illegal or unauthorized purpose, or engage
        in, encourage or promote any activity that violates these Terms.
        Enforcement of this Section 5 is solely at {config.nameOfCompany}’s
        discretion, and failure to enforce this section in some instances does
        not constitute a waiver of our right to enforce it in other instances.
        In addition, this Section 5 does not create any private right of action
        on the part of any third party or any reasonable expectation that the
        Services will not contain any content that is prohibited by such rules
      </Paragraph>
      <MainHeading variant="h4">6. Ownership; Limited License</MainHeading>
      <Paragraph variant="body2">
        The Services, including the text, graphics, images, photographs, videos,
        illustrations and other content contained therein, are owned by
        {config.nameOfCompany} or our licensors and are protected under both
        United States and foreign laws. Except as explicitly stated in these
        Terms, all rights in and to the Services are reserved by us or our
        licensors. Subject to your compliance with these Terms, you are hereby
        granted a limited, non-exclusive, non-transferable, non-sublicensable,
        revocable license to access and use our Services for your own personal,
        non-commercial use. Any use of the Services other than as specifically
        authorized herein, without our prior written permission, is strictly
        prohibited, will terminate the license granted herein, and violate our
        intellectual property rights.
      </Paragraph>

      <MainHeading variant="h4">7. Trademarks</MainHeading>
      <Paragraph variant="body2">
        “{config.nameOfCompany}”{' '}
        {partnerName === 'instaview' ? 'and “InstaBaby”,' : ''} and our logos,
        our product or service names, our slogans and the look and feel of the
        Services are trademarks of {config.nameOfCompany} and may not be copied,
        imitated or used, in whole or in part, without our prior written
        permission. All other trademarks, registered trademarks, product names
        and company names or logos mentioned on the Services are the property of
        their respective owners. Reference to any products, services, processes
        or other information by trade name, trademark, manufacturer, supplier or
        otherwise does not constitute or imply endorsement, sponsorship or
        recommendation by us.
      </Paragraph>
      <MainHeading variant="h4">8. Feedback</MainHeading>
      <Paragraph variant="body2">
        You may voluntarily submit a customer review on our website, email us at{' '}
        {config.supportEmail2}, or otherwise communicate to us any questions,
        comments, suggestions, ideas, original or creative materials or other
        information about {config.nameOfCompany} or our Services (collectively,
        “Feedback”). You understand that we may use such Feedback for any
        purpose, commercial or otherwise, without acknowledgment or compensation
        to you, including, without limitation, to develop, copy, publish, or
        improve the Feedback in {config.nameOfCompany}&apos; s sole discretion.
        You understand that {config.nameOfCompany} may treat Feedback as
        non-confidential.
      </Paragraph>
      <MainHeading variant="h4">9. Third-Party Content</MainHeading>
      <Paragraph variant="body2">
        We may provide information about third-party products, services,
        activities or events, or we may allow third parties to make their
        content and information available on or through the Services
        (collectively, &quot;Third-Party Content&quot;). We provide Third-Party
        Content as a service to those interested in such content. Your dealings
        or correspondence with third parties and your use of or interaction with
        any Third-Party Content are solely between you and the third party.{' '}
        {config.nameOfCompany} does not control or endorse, and makes no
        representations or warranties regarding, any Third-Party Content, and
        your access to and use of such Third-Party Content is at your own risk.
      </Paragraph>
      <MainHeading variant="h4">10. Indemnification</MainHeading>
      <Paragraph variant="body2">
        To the fullest extent permitted by applicable law, you will indemnify,
        defend and hold harmless {config.nameOfCompany} and our subsidiaries and
        affiliates, and each of our respective officers, directors, agents,
        partners and employees (individually and collectively, the &quot;
        {config.nameOfCompany}
        Parties&quot;) from and against any losses, liabilities, claims,
        demands, damages, expenses or costs (“Claims”) arising out of or related
        to (a) your access to or use of the Services; (b) your Feedback; (c)
        your violation of these Terms; (d) your violation, misappropriation or
        infringement of any rights of another (including intellectual property
        rights or privacy rights); or (e) your conduct in connection with the
        Services. You agree to promptly notify the {config.nameOfCompany}{' '}
        Parties of any third-party Claims, cooperate with the{' '}
        {config.nameOfCompany} Parties in defending such Claims, and pay all
        fees, costs and expenses associated with defending such Claims
        (including, but not limited to, attorneys fees). You also agree that the{' '}
        {config.nameOfCompany} Parties will have control of the defense or
        settlement, at {config.nameOfCompany}&apos;s sole option, of any
        third-party Claims. This indemnity is in addition to, and not in lieu
        of, any other indemnities set forth in a written agreement between you
        and {config.nameOfCompany} or the other {config.nameOfCompany} Parties.
      </Paragraph>
      <MainHeading variant="h4">11. Disclaimers</MainHeading>
      <Paragraph variant="body2">
        Your use of our Services is at your sole risk. Except as otherwise
        provided in a writing by us, our Services and any content therein are
        provided &quot;as is&quot; and &quot;as available&quot; without
        warranties of any kind, either express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, title, and non-infringement. In addition,{' '}
        {config.nameOfCompany} does not represent or warrant that our Services
        are accurate, complete, reliable, current or error-free. While{' '}
        {config.nameOfCompany} attempts to make your use of our Services and any
        content therein safe, we cannot and do not represent or warrant that our
        Services or servers are free of viruses or other harmful components. You
        assume the entire risk as to the quality and performance of the Services
      </Paragraph>

      <MainHeading variant="h4">12. Limitation of Liability</MainHeading>
      <Paragraph variant="body2">
        To the fullest extent permitted by applicable law,{' '}
        {config.nameOfCompany} and the other {config.nameOfCompany} Parties will
        not be liable to you under any theory of liability—whether based in
        contract, tort, negligence, strict liability, warranty, or otherwise—for
        any indirect, consequential, exemplary, incidental, punitive or special
        damages or lost profits, even if {config.nameOfCompany} or the other{' '}
        {config.nameOfCompany} Parties have been advised of the possibility of
        such damages.
      </Paragraph>
      <Paragraph variant="body2">
        The total liability of {config.nameOfCompany} and the other{' '}
        {config.nameOfCompany} Parties for any claim arising out of or relating
        to these Terms or our Services, regardless of the form of the action, is
        limited to the amount paid by you, if any, to use our Services.
      </Paragraph>
      <Paragraph variant="body2">
        The limitations set forth in this Section 12 will not limit or exclude
        liability for the gross negligence, fraud or intentional misconduct of
        {config.nameOfCompany} or the other {config.nameOfCompany} Parties, or
        for any other matters in which liability cannot be excluded or limited
        under applicable law. Additionally, some jurisdictions do not allow the
        exclusion or limitation of incidental or consequential damages, so the
        above limitations or exclusions may not apply to you.
      </Paragraph>
      <MainHeading variant="h4">13. Release</MainHeading>
      <Paragraph variant="body2">
        To the fullest extent permitted by applicable law, you release{' '}
        {config.nameOfCompany} and the other {config.nameOfCompany} Parties from
        responsibility, liability, claims, demands and/or damages (actual and
        consequential) of every kind and nature, known and unknown (including,
        but not limited to, claims of negligence), arising out of or related to
        the acts or omissions of third parties or disputes between users. If you
        are a consumer who resides in California, you hereby waive your rights
        under California Civil Code § 1542, which provides: “A general release
        does not extend to claims which the creditor does not know or suspect to
        exist in his or her favor at the time of executing the release, which if
        known by him or her must have materially affected his or her settlement
        with the debtor.”
      </Paragraph>
      <MainHeading variant="h4">14. Transfer and Processing Data</MainHeading>
      <Paragraph variant="body2">
        {config.nameOfCompany} is based in the United States and we process and
        store information in the United States. We and our service providers may
        transfer your information to, or store or access it in, other
        jurisdictions that may not provide equivalent levels of data protection
        as the United States. By using the Services, you consent to the transfer
        of your information outside the United States to other jurisdictions.
      </Paragraph>
      <MainHeading variant="h4">
        15. Dispute Resolution; Binding Arbitration; Waiver of Representative
        Actions
      </MainHeading>
      <Paragraph variant="body2">
        Please read the following section carefully because it requires you to
        arbitrate certain disputes and claims with {config.nameOfCompany} and
        limits the manner in which you can seek relief from us, unless you opt
        out of arbitration by following the instructions set forth below. No
        class or representative actions or arbitrations are allowed under this
        arbitration agreement. In addition, arbitration precludes you from suing
        in court or having a jury trial.
      </Paragraph>
      <Paragraph
        variant="body2"
        style={{ textTransform: 'uppercase', fontWeight: '600' }}
      >
        NO REPRESENTATIVE ACTIONS. YOU AND {config.nameOfCompany} AGREE THAT ANY
        DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS OR OUR SERVICES IS
        PERSONAL TO YOU AND {config.nameOfCompany}, THAT ANY DISPUTE WILL BE
        RESOLVED SOLELY THROUGH INDIVIDUAL ACTION, AND WILL NOT BE BROUGHT AS A
        CLASS ARBITRATION, CLASS ACTION OR ANY OTHER TYPE OF REPRESENTATIVE
        PROCEEDING.
      </Paragraph>
      <Paragraph variant="body2">
        Arbitration of Disputes. Except for small claims disputes in which you
        or {config.nameOfCompany} seeks to bring an individual action in small
        claims court located in the county of your billing address or disputes
        in which you or {config.nameOfCompany} seeks injunctive or other
        equitable relief for the alleged unlawful use of intellectual property,
        you and {config.nameOfCompany} waive your rights to a jury trial and to
        have any dispute arising out of or related to these Terms or our
        Services resolved in court. Instead, for any dispute or claim that you
        have against {config.nameOfCompany} or relating in any way to the
        Services, you agree to first contact {config.nameOfCompany} and attempt
        to resolve the claim informally by sending a written notice of your
        claim (“Notice”) to {config.nameOfCompany} by email at{' '}
        {config.supportEmail2} or by certified mail addressed to{' '}
        {config.nameOfCompany},
        {partnerName === 'instaview'
          ? '2244 Faraday Avenue, Carlsbad, CA 92008.'
          : '450 North 1500 West, Orem, UT 84057.'}{' '}
        The Notice must (a) include your name, residence address, email address,
        and telephone number; (b) describe the nature and basis of the claim;
        and (c) set forth the specific relief sought. Our notice to you will be
        similar in form to that described above. If you and{' '}
        {config.nameOfCompany} cannot reach an agreement to resolve the claim
        within thirty (30) days after such Notice is received, then either party
        may submit the dispute to binding arbitration administered by JAMS or,
        under the limited circumstances set forth above, in court. All disputes
        submitted to JAMS will be resolved through confidential, binding
        arbitration before one arbitrator. Arbitration proceedings will be held
        in Salt Lake County, Utah, or may be conducted telephonically or via
        video conference for disputes alleging damages less than USD$5,000, in
        accordance with the JAMS Streamlined Arbitration Rules and Procedures
        (“JAMS Rules”). The most recent version of the JAMS Rules is available
        on the JAMS website and the JAMS Rules are hereby incorporated by
        reference. You either acknowledge and agree that you have read and
        understand the JAMS Rules, or waive your opportunity to read the JAMS
        Rules and waive any claim that the JAMS Rules are unfair or should not
        apply for any reason
      </Paragraph>
      <Paragraph variant="body2">
        You and {config.nameOfCompany} agree that these Terms affect interstate
        commerce and that the enforceability of this Section 15 will be
        substantively and procedurally governed by the Federal Arbitration Act,
        9 U.S.C. § 1, et seq. (the “FAA”), to the maximum extent permitted by
        applicable law. As limited by the FAA, these Terms and the JAMS Rules,
        the arbitrator will have exclusive authority to make all procedural and
        substantive decisions regarding any dispute and to grant any remedy that
        would otherwise be available in court, including the power to determine
        the question of arbitrability. The arbitrator may conduct only an
        individual arbitration and may not consolidate more than one
        individual&apos;s claims, preside over any type of class or
        representative proceeding, or preside over any proceeding involving more
        than one individual.
      </Paragraph>
      <Paragraph variant="body2">
        The arbitrator, {config.nameOfCompany}, and you will maintain the
        confidentiality of any arbitration proceedings, judgments and awards,
        including, but not limited to, all information gathered, prepared and
        presented for purposes of the arbitration or related to the dispute(s)
        therein. The arbitrator will have the authority to make appropriate
        rulings to safeguard confidentiality, unless the law provides to the
        contrary. The duty of confidentiality does not apply to the extent that
        disclosure is necessary to prepare for or conduct the arbitration
        hearing on the merits, in connection with a court application for a
        preliminary remedy, in connection with a judicial challenge to an
        arbitration award or its enforcement, or to the extent that disclosure
        is otherwise required by law or judicial decision.
      </Paragraph>
      <Paragraph variant="body2">
        You and {config.nameOfCompany} agree that for any arbitration you
        initiate, you will pay the filing fee and {config.nameOfCompany} will
        pay the remaining JAMS fees and costs. For any arbitration initiated by{' '}
        {config.nameOfCompany}, {config.nameOfCompany}
        will pay all JAMS fees and costs. You and {config.nameOfCompany} agree
        that the state or federal courts of the State of Utah and the United
        States sitting in Salt Lake County have exclusive jurisdiction over any
        appeals and the enforcement of an arbitration award.
      </Paragraph>
      <Paragraph variant="body2">
        Any claim arising out of or related to these Terms or our Services must
        be filed within one year after such claim arose; otherwise, the claim is
        permanently barred, which means that you and {config.nameOfCompany} will
        not have the right to assert the claim.
      </Paragraph>
      <Paragraph variant="body2">
        You have the right to opt out of binding arbitration within 30 days of
        the date you first accepted the terms of this Section 15 by sending an
        email to {config.supportEmail2}. In order to be effective, the opt-out
        notice must include your full name and address and clearly indicate your
        intent to opt out of binding arbitration. By opting out of binding
        arbitration, you are agreeing to resolve disputes in accordance with
        Section 15.
      </Paragraph>
      <Paragraph variant="body2">
        If any portion of this Section 15 is found to be unenforceable or
        unlawful for any reason, (a) the unenforceable or unlawful provision
        shall be severed from these Terms; (b) severance of the unenforceable or
        unlawful provision shall have no impact whatsoever on the remainder of
        this Section 15 or the parties&apos; ability to compel arbitration of
        any remaining claims on an individual basis pursuant to this Section 15;
        and (c) to the extent that any claims must therefore proceed on a class,
        collective, consolidated, or representative basis, such claims must be
        litigated in a civil court of competent jurisdiction and not in
        arbitration, and the parties agree that litigation of those claims shall
        be stayed pending the outcome of any individual claims in arbitration.
        Further, if any part of this Section 15 is found to prohibit an
        individual claim seeking public injunctive relief, that provision will
        have no effect to the extent such relief is allowed to be sought out of
        arbitration, and the remainder of this Section 15 will be enforceable.
      </Paragraph>
      <MainHeading variant="h4">16. Governing Law and Venue</MainHeading>
      <Paragraph variant="body2">
        Any dispute arising from these Terms and your use of the Services will
        be governed by and construed and enforced in accordance with the laws of
        Utah, except to the extent preempted by U.S. federal law, without regard
        to conflict of law rules or principles (whether of Utah or any other
        jurisdiction) that would cause the application of the laws of any other
        jurisdiction. Any dispute between the parties that is not subject to
        arbitration or cannot be heard in small claims court will be resolved in
        the state or federal courts of Utah and the United States, respectively,
        Salt Lake County, Utah.
      </Paragraph>
      <MainHeading variant="h4">
        17. {config.nameOfCompany}’s Services Cancellation and Refund Policy
      </MainHeading>
      <Paragraph variant="body2">
        The Refund Policy applies to users who purchase a subscription to our
        Services. By subscribing, you acknowledge and agree to the following
        terms:
      </Paragraph>
      <Paragraph variant="body1">
        <b>Cancellation & Refund Policy</b>
      </Paragraph>
      <Paragraph variant="body2">
        <ul style={{ paddingInlineStart: '16px' }}>
          <li>
            Annual Plans cancelled after the refund period of 30 days will not
            result in any reimbursement. Monthly plans cancelled will prevent
            renewal for the following billing cycle but will not result in a
            refund for the current billing period.
          </li>
          <li>
            No exceptions, refunds outside of the terms outlined above will not
            be provided under any circumstances. Cancellation requests must be
            submitted by calling us at {config.phoneNumber} and by sending a
            written request via email to{' '}
            {partnerName === 'instaview'
              ? config.supportEmail
              : config.supportEmail2}{' '}
            for subscribed users.
          </li>
          <li>
            There&apos;s strictly no refunds available for monthly
            subscriptions. However, you may cancel your subscription to prevent
            further charges in subsequent months.
          </li>
          <li>
            If you are charged for an annual plan, you are eligible for a refund
            of 90% of the charged amount if the request is made within 30 days
            of the charge. Refunds will not be issued after this 30-day period.
            No exceptions, refunds outside of the terms outlined above will not
            be provided under any circumstances.
          </li>
        </ul>
      </Paragraph>
      <MainHeading variant="h4">
        18. Modifying and Terminating our Services
      </MainHeading>
      <Paragraph variant="body2">
        We reserve the right to modify our Services or to suspend or stop
        providing all or portions of our Services at any time. You also have the
        right to stop using our Services at any time. We are not responsible for
        any loss or harm related to your inability to access or use our
        Services.
      </Paragraph>
      <MainHeading variant="h4">19. Severability</MainHeading>
      <Paragraph variant="body2">
        If any provision or part of a provision of these Terms is unlawful, void
        or unenforceable, that provision or part of the provision is deemed
        severable from these Terms and does not affect the validity and
        enforceability of any remaining provisions.
      </Paragraph>
      <MainHeading variant="h4">20. Notice Regarding Apple</MainHeading>
      <Paragraph variant="body2">
        If you are using our mobile applications on an iOS device, the terms of
        this Section 19 apply. You acknowledge that these Terms are between you
        and {config.nameOfCompany} only, not with Apple, and Apple is not
        responsible for the Services or its content. Apple has no obligation to
        furnish any maintenance or support services with respect to the
        Services. If our mobile app fails to conform to any applicable warranty,
        you may notify Apple and Apple will refund any applicable purchase price
        for the mobile application to you; and, to the maximum extent permitted
        by applicable law, Apple has no other warranty obligation with respect
        to the Services. Apple is not responsible for addressing any claim by
        you or any third party relating to the Services or your possession or
        use of the Services, including: (a) product liability claims; (b) any
        claim that the Services fail to conform to any applicable legal or
        regulatory requirement; and (c) claims arising under consumer protection
        or similar legislation. Apple is not responsible for the investigation,
        defense, settlement or discharge of any third-party claim that the
        Services or your possession and use of the mobile application infringe
        that third-party&apos;s intellectual property rights. You agree to
        comply with any applicable third-party terms when using the Services.
        Apple and Apple&apos;s subsidiaries are third-party beneficiaries of
        these Terms, and upon your acceptance of these Terms, Apple will have
        the right (and will be deemed to have accepted the right) to enforce
        these Terms against you. You hereby represent and warrant that: (i) you
        are not located in a country that is subject to a U.S. Government
        embargo, or that has been designated by the U.S. Government as a
        “terrorist supporting” country; and (ii) you are not listed on any U.S.
        Government list of prohibited or restricted parties.
      </Paragraph>
      <MainHeading variant="h4">21. SMS Terms of Service</MainHeading>
      <Paragraph variant="body2">
        By opting in to receive SMS notifications from {config.nameOfCompany},
        you agree to the following terms and conditions:
      </Paragraph>
      <Paragraph variant="body2">
        <ul style={{ paddingInlineStart: '16px' }}>
          <li>
            <b>Consent to Receive SMS:</b> By providing your mobile phone number
            and opting in to receive SMS notifications, you consent to receive
            text messages from {config.nameOfCompany} regarding product updates,
            promotions, and important information related to your account.
          </li>
          <li>
            <b>Message Frequency:</b> You acknowledge that message frequency may
            vary and that you may receive recurring SMS notifications. Message
            frequency will depend on the type of notifications you&apos;ve
            subscribed to and may include promotional messages, reminders, and
            alerts.
          </li>
          <li>
            <b>Opting Out:</b> You have the right to opt out of receiving SMS
            notifications at any time. To opt out, simply text &quot;STOP&quot;
            to {config.phoneNumber}. You may also contact customer support for
            assistance with opting out. Message and Data Rates May Apply:
            Standard message and data rates may apply to SMS notifications
            depending on your mobile carrier and plan. Contact your mobile
            service provider for details on messaging and data rates.
          </li>
          <li>
            <b>Privacy and Security:</b>
            We are committed to protecting your privacy and personal
            information. Your mobile phone number will only be used for the
            purpose of sending SMS notifications related to{' '}
            {config.nameOfCompany}. We will not share or sell your phone number
            to third parties for marketing purposes without your consent.
          </li>
          <li>
            <b>Support:</b> For assistance with SMS notifications or to update
            your preferences, please contact our customer support team at{' '}
            {partnerName === 'instaview'
              ? config.supportEmail
              : config.supportEmail2}
            .
          </li>
          <li>
            <b>Changes to Terms:</b> {config.nameOfCompany} reserves the right
            to update or modify these SMS terms of service at any time. Any
            changes will be effective immediately upon posting the revised terms
            on our website. Your continued use of SMS notifications constitutes
            acceptance of the updated terms.
          </li>
        </ul>
      </Paragraph>
      <Paragraph variant="body2">
        By opting in to receive SMS notifications from {config.nameOfCompany},
        you acknowledge that you have read, understood, and agree to these SMS
        terms of service. If you do not agree to these terms, please refrain
        from opting in to receive SMS notifications.
      </Paragraph>
      <MainHeading variant="h4">22. Miscellaneous</MainHeading>
      <Paragraph variant="body2">
        The failure of {config.nameOfCompany} to exercise or enforce any right
        or provision of these Terms will not operate as a waiver of such right
        or provision. The section titles in these Terms are for convenience only
        and have no legal or contractual effect. Except as otherwise provided
        herein, these Terms are intended solely for the benefit of the parties
        and are not intended to confer third-party beneficiary rights upon any
        other person or entity. You agree that communications and transactions
        between us may be conducted electronically. Under California Civil Code
        § 1789.3, California consumers are entitled to the following specific
        consumer rights notice: The Complaint Assistance Unit of the Division of
        Consumer Services of the California Department of Consumer Affairs may
        be contacted in writing at 1625 N. Market Blvd., Suite N-112,
        Sacramento, California 95834, or by telephone at 1 (800) 952-5210.
      </Paragraph>
      <MainHeading variant="h4">23. Questions</MainHeading>
      <Paragraph variant="body2">
        If you have any questions about these Terms or our Services, please
        contact us by email at {config.supportEmail2} or by text or phone at{' '}
        {config.phoneNumber}
      </Paragraph>
    </MainContainer>
  );
}
export default TermsOfService;
