/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
import { Link, Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

function Footer() {
  return (
    <div className="footer-container">
      {!isMobile && (
        <Stack mb={4} pl="120px">
          <Typography
            variant="Body1SemiBold"
            style={{ margin: '28px 0', textAlign: 'left', bottom: 0 }}
          >
            <Link
              href="/privacy-policy"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
                margin: '4px 12px 0 0'
              }}
            >
              Privacy Policy
            </Link>
            <span>|</span>
            <Link
              href="/terms-of-service"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
                margin: '4px 0 0 12px'
              }}
            >
              Terms of Service
            </Link>
          </Typography>
        </Stack>
      )}

      {isMobile && (
        <Stack mb={4}>
          <Typography
            variant="Body1SemiBold"
            style={{ margin: '28px 0', textAlign: 'center' }}
          >
            <Link
              href="/privacy-policy"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
                margin: '4px 12px 0 0'
              }}
            >
              Privacy Policy
            </Link>
            <span>|</span>
            <Link
              href="/terms-of-service"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
                margin: '4px 0 0 12px'
              }}
            >
              Terms of Service
            </Link>
          </Typography>
        </Stack>
      )}
    </div>
  );
}

export default Footer;
