import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ConfigContext } from 'context';
import { useContext } from 'react';
import { IsMobile } from 'hooks/isMobile';
import { getPartnerName } from 'partner_configuration';

const MainContainer = styled('main')`
  padding: 40px;
  text-align: justify;
`;

const Title = styled(Typography)`
  margin-bottom: 32px;
  font-weight: 600;
`;

const Paragraph = styled(Typography)`
  margin-bottom: 20px;
`;

const LastUpdatedInfoContainer = styled(Typography)`
  margin-bottom: 24px;
  font-weight: 600;
`;

const MainHeading = styled(Typography)`
  margin-bottom: 24px;
  font-weight: 600;
`;

const CompanyDetails = styled('div')`
  text-align: left;
  margin-bottom: 24px;
`;

const CompanyDetailsItem = styled('p')`
  text-decoration: none;
  text-align: left;
  height: fit-content;
  margin: 0 0 8px 0;
`;
function PrivacyPolicy() {
  const config = useContext(ConfigContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  const partnerName = getPartnerName(url);
  return (
    <MainContainer style={{ padding: IsMobile() ? '40px' : '80px' }}>
      <Title variant="h1">PRIVACY POLICY</Title>
      <LastUpdatedInfoContainer variant="body2">
        Last Updated: {config.updateDeteOfPrivacyPolicy}
      </LastUpdatedInfoContainer>
      <Paragraph variant="body2">
        At {config.nameOfCompany}, {config.typeOfCompany}, d/b/a{' '}
        {config.nameOfCompany} (&quot;
        {config.nameOfCompany}
        ,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), your
        privacy is important to us. This Privacy Policy explains how we collect,
        use, and share information we obtain through our {
          config.nameOfCompany
        }{' '}
        Camera system(s) (&quot;device(s)&quot;), mobile app, and website
        (collectively &quot;Service&quot; or &quot;Services&quot;), and when you
        otherwise interact with us, including on social media or through a
        customer support channel.
      </Paragraph>
      <Paragraph variant="body2">
        We may change this Privacy Policy from time to time. If we make changes,
        we will notify you by revising the date at the top of the policy and, in
        some cases, we may provide you with additional notice by sending you a
        notification. We encourage you to review the Privacy Policy whenever you
        access the Services or otherwise interact with us to stay informed about
        our information practices and the choices available to you.
      </Paragraph>
      <MainHeading variant="h4">Information You Provide</MainHeading>
      <Paragraph variant="body2">
        We collect information you provide directly to us. For instance, in
        order to use a device, we require you to register for an account by
        providing your name, email address, and phone number. In order to
        customize and enhance your experience and the messages we send you, we
        may ask you to provide additional information.
      </Paragraph>
      <Paragraph variant="body2">
        We also collect any information you provide when you sign up for our
        mailing list, purchase a device, register your device, request customer
        support, participate in any interactive features of the Services,
        communicate with us via third-party social media sites, apply for a job,
        or otherwise communicate with us.
      </Paragraph>
      <MainHeading variant="h4">
        Information We Collect Through Our Devices
      </MainHeading>
      <Paragraph variant="body2">
        When you use our device, we collect real-time audio and video data from
        the device. We utilize this data to detect and gather information on
        various vital signs, including physiological indicators, as well as to
        identify and collect data on entities and objects present in the scene
      </Paragraph>
      <MainHeading variant="h4">
        Information We Collect Automatically
      </MainHeading>
      <Paragraph variant="body2">
        When you access or use our Services, we automatically collect
        information about those interactions, including the following
      </Paragraph>
      <Paragraph variant="body2">
        Log Information: We collect log information about your use of the
        Services, including the type of browser you use, app version, your IP
        address, access times, pages viewed, and the page you visited before
        navigating to our Services. Device Information: We collect information
        about the computer or mobile device you use to access our Services,
        including the hardware model, operating system and version, unique
        device identifiers, and mobile network information. Information
        Collected by Cookies and Other Tracking Technologies: We use different
        technologies to collect information, including cookies and web beacons.
        Cookies are small data files stored on your hard drive or in device
        memory that help us improve our Services and your experience, see which
        areas and features of our Services are popular, and count visits. Web
        beacons are electronic images that may be used in our Services or emails
        and help deliver cookies, count visits, and understand usage and
        campaign effectiveness. For more information about cookies and how to
        disable them, please see &quot;Your Choices&quot; below. In addition, we
        may obtain information from other sources and combine that with
        information we collect through our Services. For example, if you create
        or log into your account through a social media site, we will have
        access to certain information from that site, such as your name, account
        information, and friends lists, in accordance with the authorization
        procedures determined by such social media site.
      </Paragraph>
      <MainHeading variant="h4">
        How We Use the Information We Collect
      </MainHeading>
      <Paragraph variant="body2">
        We use the information we collect to provide, maintain, and improve our
        Services, such as to deliver the Services you request, customize your
        experience, and send you notifications of activities and events
        associated with your account. We may also use the information we collect
        to:
      </Paragraph>
      <Paragraph variant="body2">
        Send you technical notices, updates, security alerts, and support and
        administrative messages, and respond to your comments, questions, and
        customer service requests; Communicate with you about our Services,
        offers, and events offered by {config.nameOfCompany} and others, and
        provide news and information we think will be of interest to you;
        Monitor and analyze trends, usage, and activities in connection with our
        Services; and Process contest and promotion entries and deliver rewards.
      </Paragraph>
      <MainHeading variant="h4">
        How We Share the Information We Collect
      </MainHeading>
      <Paragraph variant="body2">
        We will not share data except in the following circumstances:
      </Paragraph>

      <Paragraph variant="body2">
        With vendors, consultants, and other service providers who need access
        to such information to carry out work on our behalf; In response to a
        request for information if we believe disclosure is in accordance with,
        or required by, any applicable law or legal process, including lawful
        requests by public authorities to meet national security or law
        enforcement requirements; If we believe your actions are inconsistent
        with our user agreements or policies, or to protect the rights,
        property, and safety of {config.nameOfCompany} or others; In connection
        with, or during negotiations of, any merger, sale of company assets,
        financing or acquisition of all or a portion of our business by another
        company; and with your consent or at your direction.
      </Paragraph>
      <MainHeading variant="h4">Social Sharing</MainHeading>
      <Paragraph variant="body2">
        The Services may offer social-sharing features and other integrated
        tools (such as the Facebook &quot;Like&quot; button). Your use of such
        features enables the sharing of information with your friends or the
        public, depending on the settings you establish with the entity that
        provides the social-sharing feature. For more information about the
        purpose and scope of data collection and processing through
        social-sharing features, please visit the privacy policies of the
        entities that provide these features
      </Paragraph>
      <Paragraph variant="body2">
        We may allow others to provide analytics services and serve
        advertisements on our behalf across the web and in mobile applications.
        These entities may use cookies, web beacons, device identifiers and
        other technologies to collect information about your use of our website
        and mobile application and other websites and applications, including
        your IP address, web browser, mobile network information, pages viewed,
        time spent on pages or in apps, links clicked, and conversion
        information. This information may be used by {config.nameOfCompany} and
        others to, among other things, analyze and track data, determine the
        popularity of certain content, deliver advertising and content targeted
        to your interests on our Services and other websites, and better
        understand your online activity. For more information about
        interest-based ads, or to opt out of having your web-browsing
        information used for behavioral advertising purposes, please visit
        www.aboutads.info/choices. Your device may also include a feature
        (&quot;Limit Ad Tracking&quot; on iOS, or &quot;Opt Out of
        Interest-Based Ads&quot; or &quot;Opt Out of Ads Personalization&quot;
        on Android) that allows you to opt out of having certain information
        collected through apps used for behavioral advertising purposes.
      </Paragraph>
      <Paragraph variant="body2">
        We store the other information we collect for as long as is necessary
        for the purpose(s) for which we originally collected it, or for other
        legitimate business purposes, including to meet our legal, regulatory,
        or other compliance obligations. but may delete such information sooner
        or retain it longer, such as if you ask us to store it for a longer
        period.
      </Paragraph>
      <MainHeading variant="h4">
        Transfer of Information to the U.S. and Other Countries
      </MainHeading>
      <Paragraph variant="body2">
        {config.nameOfCompany} is based in the United States and we process and
        store information in the United States. Our Services are not intended
        for use outside of the United States. If you use our Services outside of
        the United States, you consent to the transfer of your information in
        the United States and other jurisdictions where it may not enjoy the
        same level of protection as it does in your home country.
      </Paragraph>
      <MainHeading variant="h4">Account Information</MainHeading>
      <Paragraph variant="body2">
        You may update, correct, or delete your information at any time by
        logging into your account or emailing us at{' '}
        {partnerName === 'instaview'
          ? config.supportEmail
          : config.supportEmail2}
        . If you wish to delete or deactivate your account, please email us at{' '}
        {partnerName === 'instaview'
          ? config.supportEmail
          : config.supportEmail2}
        , but note that we may retain certain information as required by law or
        for legitimate business purposes. We may also retain cached or archived
        copies of information about you for a certain period of time.
      </Paragraph>
      <MainHeading variant="h4">Cookies</MainHeading>
      <Paragraph variant="body2">
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove or reject browser
        cookies. Please note that if you choose to remove or reject cookies,
        this could affect the availability and functionality of our Services.
      </Paragraph>
      <MainHeading variant="h4">Promotional Communications</MainHeading>
      <Paragraph variant="body2">
        You may opt out of receiving promotional emails from{' '}
        {config.nameOfCompany} by following the instructions in those emails. If
        you opt out, we may still send you non-promotional emails, such as those
        about your account or our ongoing business relations.
      </Paragraph>
      <MainHeading variant="h4">Push Notifications</MainHeading>
      <Paragraph variant="body2">
        With your consent, we may send promotional and non-promotional push
        notifications or alerts to your mobile device. You can deactivate these
        messages at any time by changing the notification settings on your
        mobile device.
      </Paragraph>

      <Paragraph variant="body2">
        We do not market our Services to children, and we never knowingly
        collect personal information from children. If you are the parent or
        guardian of a child under 18 and believe your child has provided
        personal information to us, please email us at{' '}
        {partnerName === 'instaview'
          ? config.supportEmail
          : config.supportEmail2}
        .
      </Paragraph>

      <Paragraph variant="body2">
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <CompanyDetails>
          <CompanyDetailsItem>{config.nameOfCompany}</CompanyDetailsItem>
          <CompanyDetailsItem>{config.companyAddress}</CompanyDetailsItem>
          <CompanyDetailsItem>{config.phoneNumber}</CompanyDetailsItem>
        </CompanyDetails>
      </Paragraph>
    </MainContainer>
  );
}
export default PrivacyPolicy;
