import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { signInWithEmailAndPassword } from 'firebase/auth';
// import Email from 'assets/email.png';
// import Password from 'assets/lock.png';
import { fetchUser } from 'store/reducers/userReducers';
import { auth } from 'configs/firebaseConfig';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestHeaders } from 'utils/commonFunctions';
import { leftNavData } from 'components/SideBar/SideBar';
// import { Image } from 'styles';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import { ConfigContext } from 'context';
import CardWithDivider from 'components/CardWithDivider/CardWithDivider';
import Footer from 'pages/Footer/footer';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const config = useContext(ConfigContext);
  const LoginIllustration = config.loginIllustration;
  const Logo = config.logo;
  const onSignIn = async () => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const token = await res.user.getIdToken();
      setRequestHeaders(token);
      dispatch(fetchUser());
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  // const onGoogleSignIn = async () => {
  //   try {
  //     const res = await signInWithPopup(auth, googleProvider);
  //     const token = await res.user.getIdToken();
  //     setRequestHeaders(token);
  //     dispatch(fetchUser());
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const onAppleSignIn = async () => {
  //   try {
  //     const res = await signInWithPopup(auth, appleProvider);
  //     const credential = OAuthProvider.credentialFromResult(
  //       res
  //     ) as OAuthCredential;
  //     const { accessToken } = credential;
  //     setRequestHeaders(`${accessToken}`);
  //     dispatch(fetchUser());
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    if (location.pathname.includes('authorize_device')) {
      return;
    }
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate(`${leftNavData[0]['link']}`);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems={isMobile ? 'flex-start' : 'center'}
        direction="row"
        height="89vh"
        sx={{ boxSizing: 'border-box', p: '24px' }}
      >
        {!isMobile && (
          <Stack width="50%" justifyContent="center" alignItems="center">
            <LoginIllustration width="100%" height="inherit" />
            <Stack width="100%" justifyContent="center" alignItems="flex-start">
              <Typography
                variant="h1"
                mb="16px"
                ml="96px"
                fontSize="32px"
                lineHeight={1.2}
              >
                {config.welcomeText}
              </Typography>
              <Typography variant="h4" ml="96px" fontSize="16px">
                Unlock a more powerful camera.
              </Typography>
            </Stack>
          </Stack>
        )}
        {/* Mobile View Start From Here */}
        <Stack
          width={isMobile ? '100%' : '50%'}
          justifyContent={isMobile ? 'space-between' : 'center'}
          alignItems={isMobile ? 'flex-end' : 'center'}
          gap={isMobile ? 0 : 6}
          sx={{
            scrollBehavior: 'smooth',
            overflow: 'auto'
          }}
          mt={isMobile ? 0 : 10}
        >
          <Stack
            justifyContent="center"
            sx={{
              width: isMobile ? '100%' : '400px',
              margin: isMobile ? '32px auto' : '14px auto',
              gap: isMobile ? '' : '20px'
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              mb={4}
              position="relative"
            >
              <Logo
                width="80"
                height="80"
                style={{ position: 'absolute', left: 0 }}
              />
              <Typography
                variant="h1"
                sx={{
                  flexGrow: 1,
                  textAlign: 'center'
                }}
              >
                Sign In
              </Typography>
            </Stack>
            <CardWithDivider>
              <TextField
                type="text"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => {
                  setError(false);
                  setEmail(e.target.value);
                }}
                sx={{ padding: '2px 0' }}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <Image src={Email} width="24" height="24" />
                //     </InputAdornment>
                //   )
                // }}
              />
              <TextField
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={(e) => {
                  setError(false);
                  setPassword(e.target.value);
                }}
                sx={{ padding: '2px 0' }}
                InputProps={{
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     <Image src={Password} width="24" height="24" />
                  //   </InputAdornment>
                  // ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        className="btn-no-focus"
                        onClick={() =>
                          setShowPassword((prevState) => !prevState)
                        }
                        edge="end"
                      >
                        {showPassword ? (
                          <RemoveRedEyeSharpIcon />
                        ) : (
                          <VisibilityOffSharpIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </CardWithDivider>
            {error ? (
              <div style={{ color: 'red' }}>
                {' '}
                Email or password is incorrect!
              </div>
            ) : (
              ''
            )}

            <Button
              sx={{
                width: '100%',
                background:
                  'linear-gradient(24deg, rgba(228, 228, 228, 1), rgba(241, 241, 241, 1)) padding-box, linear-gradient(356.14deg, #D5D5D5 14.39%, #FFFFFF 78.07%) border-box',
                border: '1px solid transparent'
              }}
              variant="contained"
              disabled={!(email && password)}
              onClick={onSignIn}
            >
              Sign In
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Footer />
      {/* <Typography>Sign in with Open accounts</Typography> */}
      {/* <Stack
              direction={isMobile ? 'column' : 'row'}
              alignItems="center"
              justifyContent="space-between"
              spacing={isMobile ? 2 : 0}
              mt={2}
            >
              <Button
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  width: isMobile ? '100%' : 'auto'
                }}
                variant="contained"
                onClick={onGoogleSignIn}
              >
                <Image
                  src={GoogleLogo}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />{' '}
                Google
              </Button>
              <Button
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  width: isMobile ? '100%' : 'auto'
                }}
                variant="contained"
                onClick={onAppleSignIn}
              >
                <Image
                  src={AppleLogo}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />{' '}
                Apple ID
              </Button>
            </Stack>
            <Divider sx={{ m: 4 }}>OR</Divider> */}
      {/* <Typography>Continue with email address</Typography> */}
    </>
  );
}

export default LoginScreen;
