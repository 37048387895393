/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Divider } from '@mui/material';

function CardWithDivider(props: any) {
  const { children } = props;
  console.log('props -- ', props);
  return (
    <Card
      sx={{
        borderRadius: '1rem',
        background:
          'linear-gradient(rgba(237,237,237, 1),rgba(237, 237, 237, 1)) padding-box, linear-gradient(356.14deg, #D5D5D5 14.39%, #FFFFFF 110.07%) border-box',
        border: '1px solid transparent',
        position: 'relative',
        padding: '0 8px',
        boxSizing: 'border-box',
        boxShadow: 'none',
        marginBottom: '20px',
        overflow: 'auto',
        '&::before': {
          backgroundImage: 'linear-gradient(45deg, black, transparent)',
          borderRadius: '20px',
          padding: '1px',
          width: '100%',
          height: '100%',
          top: '-1px',
          left: '-1px',
          zIndex: '-1',
          position: 'absolute',
          content: '""'
        }
      }}
    >
      {children.map((child: any, index: any) => (
        <>
          {child}
          {index !== children.length - 1 && <Divider />}
        </>
      ))}
    </Card>
  );
}

export default CardWithDivider;
